.pagination {
  display: flex;
  align-items: center;

  span {
    border-radius: 0;
    border: 1px solid var(--border-color-main);

    &:not(:first-child) {
      border-left: 0;
    }

    &:first-child {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }

    &.current, & > a {
      display: inline-block;
      padding: var(--half-padding) var(--default-padding);
      user-select: none;
    }

    &.current {
      background-color: $blue-color;
      border-color: $blue-color;
      color: white;
    }

  }
}