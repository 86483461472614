.details-container {
  box-sizing: border-box;
  padding: var(--default-padding);

  .details-container-header {
    margin-bottom: var(--default-padding);

    .details-container-navigation {
      display: flex;
      align-items: center;

      a {
        margin-right: var(--default-padding);
        display: block;
        padding: var(--default-padding) 0;
        color: rgba(black, 0.75);
        opacity: 0.9;

        &:not(.active) {
          &:hover {
            color: black;
          }
        }

        &.active {
          position: relative;
          color: black;

          &::before {
            content: "";
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $blue-color;
          }
        }
      }
    }
  }
}