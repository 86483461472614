.alert {
  --default-border-color: #{$blue-color};

  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: var(--default-padding);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
  padding-left: $default-padding * 4;
  position: relative;
  border-left: 4px solid var(--default-border-color);

  &.alert-danger {
    --default-border-color: var(--danger);
  }

  &.alert-warning {
    --default-border-color: var(--warning);
  }

  &.alert-success {
    --default-border-color: var(--success);
  }

  &:before {
    content: "";
    width:  32px;
    height: 32px;
    background-image: url("/assets/dashboard/icons/warning.svg");
    background-size: cover;
    position: absolute;
    top:  16px;
    left: 16px;
  }

  .alert-message {
    margin-bottom: var(--default-padding);
    font-size: 1rem;
  }
}