.single-photo {
  display: flex;
  flex-direction: column;
  margin-right: var(--half-padding);
  margin-bottom: var(--half-padding);

  &.single-preview {
    width: 100px;
  }

  .delete-image {
    margin-top: var(--half-padding);
  }

  img {
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
  }
}

.photos-preview {
  display: flex;
  flex-wrap: wrap;

  &.show-updating-overlay {
    position: relative;

    .updating-overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
    }
  }

  .updating-overlay {
    display: none;
  }

}
