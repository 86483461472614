body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-base);
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;

  color: var(--text-main);

  &.signin-body {
    background-color: var(--bg-base);
  }
}

a {
  &:not(.nav-item, .button) {
    color: var(--primary);
    text-decoration: none;
    opacity: 0.8;
    transition: 0.1s all;

    &:hover {
      opacity: 1;
    }
  }
}

.vertical-spacer {
  margin-top: var(--default-padding);
}
