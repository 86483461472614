.block-placeholder {
  border-radius: 3px;
  background-color: rgba(black, 0.1);
  box-sizing: border-box;
  padding: var(--default-padding);
  text-transform: uppercase;
  text-align: center;
  color: rgba(black, 0.5);
  text-shadow: 0 1px 1px rgba(white, 0.1);
}
